/*!
 * Bootstrap Docs (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under the Creative Commons Attribution 3.0 Unported License.
 * For details, see https://creativecommons.org/licenses/by/3.0/.
 */
.bd-navbar {
  min-height: 4rem;
  background-color: #846202;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 991.98px) {
  .bd-navbar {
    padding-right: .5rem;
    padding-left: .5rem;
  }
  .bd-navbar .navbar-nav-scroll {
    max-width: 100%;
    height: 2.5rem;
    margin-top: .25rem;
    overflow: hidden;
  }
  .bd-navbar .navbar-nav-scroll .navbar-nav {
    padding-bottom: 2rem;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-navbar {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1071;
    }
  }
}

.bd-navbar .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem;
  color: rgba(255, 255, 255, 0.85);
}

.bd-navbar .navbar-nav .nav-link.active, .bd-navbar .navbar-nav .nav-link:hover {
  color: #fff;
  background-color: transparent;
}

.bd-navbar .navbar-nav .nav-link.active {
  font-weight: 600;
}

.bd-navbar .navbar-nav-svg {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-top;
}

.bd-navbar .dropdown-menu {
  font-size: 0.875rem;
}

.bd-navbar .dropdown-item.active {
  font-weight: 600;
  color: #212529;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23292b2c' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat 0.4rem 0.6rem/0.75rem 0.75rem;
}

.bd-masthead {
  position: relative;
  padding: 3rem 15px;
  background: linear-gradient(to right bottom, #ffeebe 50%, #fff 50%);
}

.bd-masthead h1 {
  font-size: 4rem;
  line-height: 1;
}

@media (max-width: 1200px) {
  .bd-masthead h1 {
    font-size: calc(1.525rem + 3.3vw) ;
  }
}

.bd-masthead .lead {
  font-size: 1.5rem;
  font-weight: 400;
  color: #495057;
}

@media (max-width: 1200px) {
  .bd-masthead .lead {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.bd-masthead .btn {
  padding: .8rem 2rem;
  font-weight: 600;
  font-size: 1.25rem;
}

.bd-masthead .carbonad {
  margin-top: 0 !important;
  margin-bottom: -3rem !important;
}

@media (min-width: 576px) {
  .bd-masthead {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .bd-masthead .carbonad {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 768px) {
  .bd-masthead .carbonad {
    margin-top: 3rem !important;
  }
}

.masthead-followup .bd-clipboard {
  display: none;
}

.masthead-followup h2 {
  font-size: 2.5rem;
}

@media (max-width: 1200px) {
  .masthead-followup h2 {
    font-size: calc(1.375rem + 1.5vw) ;
  }
}

.masthead-followup .highlight {
  border-radius: 0.5rem;
}

.masthead-followup .highlight pre::-webkit-scrollbar {
  display: none;
}

.masthead-followup .highlight pre code {
  display: inline-block;
  white-space: pre;
}

.masthead-followup .highlight pre code::before {
  display: none;
}

.masthead-followup-icon {
  padding: .75rem;
  background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.01));
  border-radius: 0.75rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.masthead-followup-svg {
  filter: drop-shadow(0 1px 0 rgba(0, 0, 0, 0.125));
}

#carbonads {
  position: static;
  display: block;
  max-width: 400px;
  padding: 15px 15px 15px 160px;
  margin: 2rem 0;
  overflow: hidden;
  font-size: 0.8125rem;
  line-height: 1.4;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.05);
}

#carbonads a {
  color: #333;
  text-decoration: none;
}

@media (min-width: 576px) {
  #carbonads {
    max-width: 330px;
    border-radius: 4px;
  }
}

.carbon-img {
  float: left;
  margin-left: -145px;
}

.carbon-poweredby {
  display: block;
  margin-top: .75rem;
  color: #777 !important;
}

.bd-content {
  order: 1;
}

.bd-content > h2[id],
.bd-content > h3[id],
.bd-content > h4[id] {
  pointer-events: none;
}

.bd-content > h2[id]::before,
.bd-content > h3[id]::before,
.bd-content > h4[id]::before {
  display: block;
  height: 6rem;
  margin-top: -6rem;
  content: "";
}

.bd-content > table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
}

@media (max-width: 991.98px) {
  .bd-content > table {
    display: block;
    overflow-x: auto;
  }
  .bd-content > table.table-bordered {
    border: 0;
  }
}

.bd-content > table > thead > tr > th,
.bd-content > table > thead > tr > td,
.bd-content > table > tbody > tr > th,
.bd-content > table > tbody > tr > td,
.bd-content > table > tfoot > tr > th,
.bd-content > table > tfoot > tr > td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #dee2e6;
}

.bd-content > table > thead > tr > th > p:last-child,
.bd-content > table > thead > tr > td > p:last-child,
.bd-content > table > tbody > tr > th > p:last-child,
.bd-content > table > tbody > tr > td > p:last-child,
.bd-content > table > tfoot > tr > th > p:last-child,
.bd-content > table > tfoot > tr > td > p:last-child {
  margin-bottom: 0;
}

.bd-content > table td:first-child > code {
  white-space: nowrap;
}

.bd-content-title {
  display: block;
  pointer-events: auto;
}

.bd-content > h2 {
  font-size: 2rem;
}

@media (max-width: 1200px) {
  .bd-content > h2 {
    font-size: calc(1.325rem + 0.9vw) ;
  }
}

.bd-content > h3 {
  font-size: 1.75rem;
}

@media (max-width: 1200px) {
  .bd-content > h3 {
    font-size: calc(1.3rem + 0.6vw) ;
  }
}

.bd-content > h4 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .bd-content > h4 {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

.bd-content > h2:not(:first-child) {
  margin-top: 3rem;
}

.bd-content > h3 {
  margin-top: 1.5rem;
}

.bd-content > ul li,
.bd-content > ol li {
  margin-bottom: .25rem;
}

@media (min-width: 992px) {
  .bd-content > ul,
  .bd-content > ol,
  .bd-content > p {
    max-width: 80%;
  }
}

.bd-title {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-size: 3rem;
}

@media (max-width: 1200px) {
  .bd-title {
    font-size: calc(1.425rem + 2.1vw) ;
  }
}

.bd-lead {
  font-size: 1.5rem;
  font-weight: 300;
}

@media (max-width: 1200px) {
  .bd-lead {
    font-size: calc(1.275rem + 0.3vw) ;
  }
}

@media (min-width: 992px) {
  .bd-lead {
    max-width: 80%;
  }
}

.bd-text-purple {
  color: #846202;
}

.bd-text-purple-bright {
  color: #846202;
}

.bd-bg-purple-bright {
  background-color: #846202;
}

.skippy {
  background-color: #846202;
}

.skippy a {
  color: #fff;
}

.skippy:focus-within a {
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0.5rem !important;
  margin: 0.25rem !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.bd-toc {
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 0.875rem;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .bd-toc {
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
  }
}

.section-nav {
  padding-left: 0;
  border-left: 1px solid #eee;
}

.section-nav ul {
  padding-left: 1rem;
}

.toc-entry {
  display: block;
}

.toc-entry a {
  display: block;
  padding: .125rem 1.5rem;
  color: #77757a;
}

.toc-entry a:hover {
  color: #007bff;
  text-decoration: none;
}

.bd-sidebar {
  order: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .bd-sidebar {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-sidebar {
      position: -webkit-sticky;
      position: sticky;
      top: 4rem;
      z-index: 1000;
      height: calc(100vh - 4rem);
    }
  }
}

@media (min-width: 1200px) {
  .bd-sidebar {
    flex: 0 1 320px;
  }
}

.bd-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .bd-links {
      max-height: calc(100vh - 9rem);
      overflow-y: auto;
    }
  }
}

@media (min-width: 768px) {
  .bd-links {
    display: block !important;
  }
}

.bd-search {
  position: relative;
  padding: 1rem 15px;
  margin-right: -15px;
  margin-left: -15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.bd-search .form-control:focus {
  border-color: #846202;
  box-shadow: 0 0 0 3px rgba(132, 98, 2, 0.25);
}

.bd-search-docs-toggle {
  color: #212529;
}

.bd-sidenav {
  display: none;
}

.bd-toc-link {
  display: block;
  padding: .25rem 1.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.65);
}

.bd-toc-link:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
}

.bd-toc-item.active {
  margin-bottom: 1rem;
}

.bd-toc-item.active:not(:first-child) {
  margin-top: 1rem;
}

.bd-toc-item.active > .bd-toc-link {
  color: rgba(0, 0, 0, 0.85);
}

.bd-toc-item.active > .bd-toc-link:hover {
  background-color: transparent;
}

.bd-toc-item.active > .bd-sidenav {
  display: block;
}

.bd-sidebar .nav > li > a {
  display: block;
  padding: .25rem 1.5rem;
  font-size: 90%;
  color: rgba(0, 0, 0, 0.65);
}

.bd-sidebar .nav > li > a:hover {
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
  background-color: transparent;
}

.bd-sidebar .nav > .active > a,
.bd-sidebar .nav > .active:hover > a {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  background-color: transparent;
}

.bd-footer {
  font-size: 0.875rem;
  text-align: center;
  background-color: #f7f7f7;
}

.bd-footer a {
  font-weight: 600;
  color: #495057;
}

.bd-footer a:hover, .bd-footer a:focus {
  color: #007bff;
}

.bd-footer p {
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .bd-footer {
    text-align: left;
  }
}

.bd-footer-links {
  padding-left: 0;
  margin-bottom: 1rem;
}

.bd-footer-links li {
  display: inline-block;
}

.bd-footer-links li + li {
  margin-left: 1rem;
}

.btn-bd-primary {
  font-weight: 600;
  color: #fff;
  background-color: #846202;
  border-color: #846202;
}

.btn-bd-primary:hover, .btn-bd-primary:active {
  color: #fff;
  background-color: #523d01;
  border-color: #523d01;
}

.btn-bd-primary:focus {
  box-shadow: 0 0 0 3px rgba(132, 98, 2, 0.25);
}

.btn-bd-download {
  font-weight: 600;
  color: #ffe484;
  border-color: #ffe484;
}

.btn-bd-download:hover, .btn-bd-download:active {
  color: #2a2730;
  background-color: #ffe484;
  border-color: #ffe484;
}

.btn-bd-download:focus {
  box-shadow: 0 0 0 3px rgba(255, 228, 132, 0.25);
}

.btn-bd-light {
  color: #6c757d;
  border-color: #dee2e6;
}

.show > .btn-bd-light, .btn-bd-light:hover, .btn-bd-light:active {
  color: #846202;
  background-color: #fff;
  border-color: #846202;
}

.btn-bd-light:focus {
  box-shadow: 0 0 0 3px rgba(132, 98, 2, 0.25);
}

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: 0.25rem;
}

.bd-callout h4 {
  margin-top: 0;
  margin-bottom: .25rem;
}

.bd-callout p:last-child {
  margin-bottom: 0;
}

.bd-callout code {
  border-radius: 0.25rem;
}

.bd-callout + .bd-callout {
  margin-top: -.25rem;
}

.bd-callout-info {
  border-left-color: #5bc0de;
}

.bd-callout-info h4 {
  color: #5bc0de;
}

.bd-callout-warning {
  border-left-color: #f0ad4e;
}

.bd-callout-warning h4 {
  color: #f0ad4e;
}

.bd-callout-danger {
  border-left-color: #d9534f;
}

.bd-callout-danger h4 {
  color: #d9534f;
}

.bd-browser-bugs td p {
  margin-bottom: 0;
}

.bd-browser-bugs th:first-child {
  width: 18%;
}

.bd-brand-logos {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
  overflow: hidden;
  color: #846202;
  background-color: #f9f9f9;
  border-radius: 0.25rem;
}

.bd-brand-logos .inverse {
  color: #fff;
  background-color: #846202;
}

.bd-brand-item {
  padding: 4rem 0;
  text-align: center;
}

.bd-brand-item + .bd-brand-item {
  border-top: 1px solid #fff;
}

.bd-brand-item h1,
.bd-brand-item h3 {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .bd-brand-item {
    display: table-cell;
    width: 1%;
  }
  .bd-brand-item + .bd-brand-item {
    border-top: 0;
    border-left: 1px solid #fff;
  }
  .bd-brand-item h1 {
    font-size: 4rem;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .bd-brand-item h1 {
    font-size: calc(1.525rem + 3.3vw) ;
  }
}

.color-swatches {
  margin: 0 -5px;
  overflow: hidden;
}

.color-swatches .bd-purple {
  background-color: #846202;
}

.color-swatches .bd-purple-light {
  background-color: #ffd96d;
}

.color-swatches .bd-purple-lighter {
  background-color: #e5e1ea;
}

.color-swatches .bd-gray {
  background-color: #f9f9f9;
}

.color-swatch {
  float: left;
  width: 4rem;
  height: 4rem;
  margin-right: .25rem;
  margin-left: .25rem;
  border-radius: 0.25rem;
}

@media (min-width: 768px) {
  .color-swatch {
    width: 6rem;
    height: 6rem;
  }
}

.swatch-blue {
  color: #fff;
  background-color: #007bff;
}

.swatch-indigo {
  color: #fff;
  background-color: #6610f2;
}

.swatch-purple {
  color: #fff;
  background-color: #6f42c1;
}

.swatch-pink {
  color: #fff;
  background-color: #e83e8c;
}

.swatch-red {
  color: #fff;
  background-color: #dc3545;
}

.swatch-orange {
  color: #212529;
  background-color: #fd7e14;
}

.swatch-yellow {
  color: #212529;
  background-color: #ffc107;
}

.swatch-green {
  color: #fff;
  background-color: #28a745;
}

.swatch-teal {
  color: #fff;
  background-color: #20c997;
}

.swatch-cyan {
  color: #fff;
  background-color: #17a2b8;
}

.swatch-white {
  color: #212529;
  background-color: #fff;
}

.swatch-gray {
  color: #fff;
  background-color: #6c757d;
}

.swatch-gray-dark {
  color: #fff;
  background-color: #343a40;
}

.swatch-100 {
  color: #212529;
  background-color: #f8f9fa;
}

.swatch-200 {
  color: #212529;
  background-color: #e9ecef;
}

.swatch-300 {
  color: #212529;
  background-color: #dee2e6;
}

.swatch-400 {
  color: #212529;
  background-color: #ced4da;
}

.swatch-500 {
  color: #212529;
  background-color: #adb5bd;
}

.swatch-600 {
  color: #fff;
  background-color: #6c757d;
}

.swatch-700 {
  color: #fff;
  background-color: #495057;
}

.swatch-800 {
  color: #fff;
  background-color: #343a40;
}

.swatch-900 {
  color: #fff;
  background-color: #212529;
}

.bd-clipboard {
  position: relative;
  display: none;
  float: right;
}

.bd-clipboard + .highlight {
  margin-top: 0;
}

@media (min-width: 768px) {
  .bd-clipboard {
    display: block;
  }
}

.btn-clipboard {
  position: absolute;
  top: .65rem;
  right: .65rem;
  z-index: 10;
  display: block;
  padding: .25rem .5rem;
  font-size: 65%;
  color: #007bff;
  background-color: #fff;
  border: 1px solid;
  border-radius: 0.25rem;
}

.btn-clipboard:hover {
  color: #fff;
  background-color: #007bff;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bd-placeholder-img-lg {
  font-size: 3.5rem;
}

@media (max-width: 1200px) {
  .bd-placeholder-img-lg {
    font-size: calc(1.475rem + 2.7vw) ;
  }
}

.hll {
  background-color: #ffc;
}

.c {
  color: #727272;
}

.k {
  color: #069;
}

.o {
  color: #555;
}

.cm {
  color: #727272;
}

.cp {
  color: #008085;
}

.c1 {
  color: #727272;
}

.cs {
  color: #727272;
}

.gd {
  background-color: #fcc;
  border: 1px solid #c00;
}

.ge {
  font-style: italic;
}

.gr {
  color: #f00;
}

.gh {
  color: #030;
}

.gi {
  background-color: #cfc;
  border: 1px solid #0c0;
}

.go {
  color: #aaa;
}

.gp {
  color: #009;
}

.gu {
  color: #030;
}

.gt {
  color: #9c6;
}

.kc {
  color: #069;
}

.kd {
  color: #069;
}

.kn {
  color: #069;
}

.kp {
  color: #069;
}

.kr {
  color: #069;
}

.kt {
  color: #078;
}

.m {
  color: #c24f19;
}

.s {
  color: #d73038;
}

.na {
  color: #006ee0;
}

.nb {
  color: #366;
}

.nc {
  color: #168174;
}

.no {
  color: #360;
}

.nd {
  color: #6b62de;
}

.ni {
  color: #727272;
}

.ne {
  color: #c00;
}

.nf {
  color: #b715f4;
}

.nl {
  color: #6b62de;
}

.nn {
  color: #007ca5;
}

.nt {
  color: #2f6f9f;
}

.nv {
  color: #033;
}

.ow {
  color: #000;
}

.w {
  color: #bbb;
}

.mf {
  color: #c24f19;
}

.mh {
  color: #c24f19;
}

.mi {
  color: #c24f19;
}

.mo {
  color: #c24f19;
}

.sb {
  color: #c30;
}

.sc {
  color: #c30;
}

.sd {
  font-style: italic;
  color: #c30;
}

.s2 {
  color: #c30;
}

.se {
  color: #c30;
}

.sh {
  color: #c30;
}

.si {
  color: #a00;
}

.sx {
  color: #c30;
}

.sr {
  color: #337e7e;
}

.s1 {
  color: #c30;
}

.ss {
  color: #fc3;
}

.bp {
  color: #366;
}

.vc {
  color: #033;
}

.vg {
  color: #033;
}

.vi {
  color: #033;
}

.il {
  color: #c24f19;
}

.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #727272;
}

.language-bash::before,
.language-sh::before {
  color: #009;
  content: "$ ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.language-powershell::before {
  color: #009;
  content: "PM> ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.anchorjs-link {
  font-weight: 400;
  color: rgba(0, 123, 255, 0.5);
  transition: color 0.15s ease-in-out, opacity 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .anchorjs-link {
    transition: none;
  }
}

.anchorjs-link:hover {
  color: #007bff;
  text-decoration: none;
}

.algolia-autocomplete {
  display: block !important;
  flex: 1;
}

.algolia-autocomplete .ds-dropdown-menu {
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  padding: .75rem 0 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

@media (min-width: 768px) {
  .algolia-autocomplete .ds-dropdown-menu {
    width: 175%;
  }
}

.algolia-autocomplete .ds-dropdown-menu::before {
  display: none !important;
}

.algolia-autocomplete .ds-dropdown-menu [class^="ds-dataset-"] {
  padding: 0 !important;
  overflow: visible !important;
  background-color: transparent !important;
  border: 0 !important;
}

.algolia-autocomplete .ds-dropdown-menu .ds-suggestions {
  margin-top: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion {
  padding: 0 !important;
  overflow: visible !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--category-header {
  padding: .125rem 1rem !important;
  margin-top: 0 !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  color: #846202 !important;
  border-bottom: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--wrapper {
  float: none !important;
  padding-top: 0 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column {
  float: none !important;
  width: auto !important;
  padding: 0 !important;
  text-align: left !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-inline {
  display: block !important;
  font-size: 0.875rem;
  color: #495057;
}

.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-inline::after {
  padding: 0 .25rem;
  content: "/";
}

.algolia-autocomplete .algolia-docsearch-suggestion--content {
  display: flex;
  flex-wrap: wrap;
  float: none !important;
  width: 100% !important;
  padding: .25rem 1rem !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--content::before {
  display: none !important;
}

.algolia-autocomplete .ds-suggestion:not(:first-child) .algolia-docsearch-suggestion--category-header {
  padding-top: .75rem !important;
  margin-top: .75rem !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.algolia-autocomplete .ds-suggestion .algolia-docsearch-suggestion--subcategory-column {
  display: none !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--title {
  display: block;
  margin-bottom: 0 !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--text {
  flex: 0 0 100%;
  max-width: 100%;
  padding: .2rem 0;
  font-size: 0.8125rem !important;
  font-weight: 400;
  line-height: 1.25 !important;
  color: #6c757d;
}

.algolia-autocomplete .algolia-docsearch-footer {
  float: none !important;
  width: auto !important;
  height: auto !important;
  padding: .75rem 1rem 0;
  font-size: 0.75rem !important;
  line-height: 1 !important;
  color: #767676 !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.algolia-autocomplete .algolia-docsearch-footer--logo {
  display: inline !important;
  overflow: visible !important;
  color: inherit !important;
  text-indent: 0 !important;
  background: none !important;
}

.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  color: #5f2dab;
  background-color: rgba(154, 132, 187, 0.12);
}

.algolia-autocomplete .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
  box-shadow: inset 0 -2px 0 0 rgba(95, 45, 171, 0.5) !important;
}

.algolia-autocomplete .ds-suggestion.ds-cursor .algolia-docsearch-suggestion--content {
  background-color: rgba(208, 189, 236, 0.15) !important;
}
/*# sourceMappingURL=docs.min.css.map */