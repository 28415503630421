//
// Placeholder svg used in the docs.
//

.bd-placeholder-img {
  @include font-size(1.125rem);
  text-anchor: middle;
  user-select: none;
}

.bd-placeholder-img-lg {
  @include font-size(3.5rem);
}
